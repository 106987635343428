
html,body {
	min-height:100%;
}

body {
	background:#f0b016;
}

.main-title {
	font-family: chuck, sans-serif; //gothic-open-shaded, sans-serif;
	color:#00ffae;
	text-shadow: #cc802c 5px 5px 0;
	max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left:12px;
  padding-right: 50px;
  z-index:5;
  margin-top:20px;
  position:relative;
  letter-spacing:2px;
  font-size:30px;
}

#chicken {
	display:block;
	position:fixed;
	right:-35vh;
	top:0;
	height:100%;
	z-index:0;
	opacity:0.4;
	@media (min-width:800px) {
		opacity:1;
	}
}

@media(min-width:1024px) {
	.main-title {
		font-size:55px;
		letter-spacing:4px;
		margin-bottom:100px;
	}
}